import { utils } from "@/api/http-common";
import Axios from "axios";
import { SettingsObj } from "@/store/organisations/settings";

export interface OrgTransactionSettings {
	topupAutoTransferAccountId?: number;
	instantSettlementAccountId?: number;
	transferReferenceValidationString?: string;
}

export class SettingsApi {
	static async fetchSettings(orgId: number) {
		return Axios.get(utils.generateUrl(`organisations/${orgId}/settings`), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchTransactionSettings(orgId: number) {
		return Axios.get(utils.generateUrl(`organisations/${orgId}/transactions/settings`), {
			headers: await utils.generateHeader()
		});
	}

	static async updateSettings(orgId: number, settingsObj: SettingsObj) {
		return Axios.put(
			utils.generateUrl(`organisations/${orgId}`),
			{ ...settingsObj },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	static async updateNotifications(orgId: number, notifications: any) {
		return Axios.put(
			utils.generateUrl(`organisations/${orgId}/settings`),
			{ ...notifications },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	static async updateFees(orgId: number, feesObj: any) {
		return Axios.put(
			utils.generateUrl(`organisations/${orgId}/settings`),
			{ ...feesObj },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	static async updateTransactionSettings(orgId: number, settingsObj: OrgTransactionSettings) {
		return Axios.put(
			utils.generateUrl(`organisations/${orgId}/transactions/settings`),
			{ ...settingsObj },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	static async fetchDefaultNotificationSubscriptions() {
		return Axios.get(utils.generateUrl(`notifications/default/email`), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchAvailableNotificationSubscriptions() {
		return Axios.get(utils.generateUrl(`notifications/available/email`), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchAvailablePushySubscriptions() {
		return Axios.get(utils.generateUrl(`notifications/available/pushy`), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchNotificationsSubscriptions(orgId: number, email: string) {
		const endpoint = email == "" || email == null ? `notifications/organisation/${orgId}/email` : `notifications/organisation/${orgId}/email/${email}`;
		return Axios.get(utils.generateUrl(endpoint), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchDefaultPushySubscriptions(orgId: number) {
		return Axios.get(utils.generateUrl(`notifications/organisation/${orgId}/pushy`), {
			headers: await utils.generateHeader()
		});
	}

	static async updateNotifierSettings(orgId: number, email: string, notifications: any) {
		return Axios.post(
			utils.generateUrl(`notifications/organisation/${orgId}/email/${email}`),
			{ ...notifications },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	static async updatePushySettings(orgId: number, config: any) {
		return Axios.post(
			utils.generateUrl(`notifications/organisation/${orgId}/pushy`),
			{ config: config },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	static async removeNotifier(orgId: number, email: string) {
		return Axios.delete(utils.generateUrl(`notifications/organisation/${orgId}/email/${email}`), {
			headers: await utils.generateHeader()
		});
	}
}
