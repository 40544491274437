import { ActionTree } from "vuex";
import { ActiveOrdersState, Order, Filters, Paging, BoxSizes, Providers, DeliveryRestrictions } from "@/store/organisations/activeOrders/types";
import { OrganisationsApi, CurrentOrderStatus, transactionsFetchParams } from "@/api/organisation-api";
import { pagingDefault } from "@/store/utils";
import { Utils } from "@/utils/";
import { RootState } from "@/store/types";
import { Address } from "@/store/organisations/addresses";

export const actions: ActionTree<ActiveOrdersState, RootState> = {
	async fetchOrders({ commit, dispatch, state }) {
		try {
			commit("loading");
			const result = await OrganisationsApi.fetchOrders(state.pagination, state.filters);
			commit("setOrders", result.data.results);
			commit("setTotalItems", result.data.count);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async fetchOrder({ commit, dispatch }, orderId) {
		try {
			commit("loading");
			const result = await OrganisationsApi.fetchOrder(orderId);
			commit("setSelectedOrder", result.data);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async fetchTransaction({ commit }, orderId: number) {
		try {
			commit("loading");
			const result = await OrganisationsApi.fetchTransaction({ page: undefined }, { order_id: orderId });
			commit("setOrderTransactions", result.data.results);
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	async fetchOrderPickupAddress({ commit, dispatch }) {
		try {
			commit("loading");
			const result = await OrganisationsApi.fetchOrderPickupAddress();
			let results = result.data.results.filter((address: Address) => {
				return address.isPrimary;
			});
			commit("setSelectedOrderAddress", results);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	clearSelectedOrder({ commit }) {
		commit("setSelectedOrder", {});
	},

	async fetchOrdersByStatus(
		{ commit, state },
		data: { statusIds: Array<number>; paymentStatus: Array<string>; orgsearch?: string } = {
			statusIds: state.activeOrderIds,
			paymentStatus: ["PAID", "UNPAID"],
			orgsearch: ""
		}
	) {
		try {
			const filters: Filters = {
				orderstatus: data.statusIds,
				paymentstatus: data.paymentStatus,
				orgsearch: data.orgsearch,
				sort: "date-",
				salesOrder: true
			};

			const pagination: Paging = {
				page: 0,
				itemsPerPage: -1,
				descending: false,
				sortBy: [],
				filters: {}
			};
			commit("loading");
			const result = await OrganisationsApi.fetchOrders(pagination, filters);
			commit("setOrders", result.data.results);
			commit("setOrderTotal", result.data.count);
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	async fetchOrderStatusses({ commit, state }) {
		try {
			if (state.orderStatusses.length > 0) return; // Order status is very constant. Dont need to call it again
			commit("loading");
			const result = await OrganisationsApi.fetchOrderStatusses();
			commit("setOrderStatusses", result.data);
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	async fetchOrderStatusOverview({ commit, dispatch }, data: { organisationId: number; paymentStatus?: Array<string>; orgsearch?: string }) {
		try {
			commit("loading");
			const result = await OrganisationsApi.fetchOrderStatusOverview(data.organisationId, data.paymentStatus, data.orgsearch);
			let results = result.data.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
			commit("setOrderStatusOverview", results);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async fetchDeliverySettings({ commit, dispatch }) {
		try {
			commit("loading");
			const result = await OrganisationsApi.fetchDeliverySettings();
			let results = result.data
				? result.data
				: {
						fee: 50,
						warehouseId: "",
						provider: {
							id: 0,
							name: ""
						}
				  };
			commit("setDeliverySettings", results);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async fetchDeliveryRestrictions({ commit, dispatch }) {
		try {
			commit("loading");
			const result = await OrganisationsApi.fetchDeliveryRestrictions();

			commit("setDeliveryRestrictions", result.data);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async updateDeliveryFee({ commit }, fee: any) {
		try {
			commit("loading");
			await OrganisationsApi.updateDeliveryFee(fee);
			commit("notifications/success", "Delivery Settings Updated", { root: true });
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	async updateRequireBoxes({ commit }, requireBoxes: boolean) {
		try {
			commit("loading");
			await OrganisationsApi.updateRequireBoxes(requireBoxes);
			commit("notifications/success", "Delivery Settings Updated", { root: true });
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	async updateDeliveryProvider({ commit }, provider: Providers) {
		try {
			commit("loading");
			await OrganisationsApi.updateDeliveryProvider(provider);
			commit("notifications/success", "Delivery Settings Updated", { root: true });
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	async fetchProviders({ commit, dispatch, state }) {
		try {
			if (state.providers.length > 0) return; // call once as providers does not change often
			commit("loading");
			const result = await OrganisationsApi.fetchProviders();
			commit("setProviders", result.data);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async fetchBoxSizes({ commit, dispatch, state }) {
		try {
			if (state.boxSizes.length > 0) return; // call once as box sizes are constant
			commit("loading");
			const result = await OrganisationsApi.fetchBoxSizes();
			let results = result.data.sort((a: any, b: any) => (a.id > b.id ? 1 : -1));
			commit("setBoxSizes", results);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async addBoxSizes({ commit, dispatch, state }, boxId: number) {
		try {
			commit("loading");
			await OrganisationsApi.addBoxSizes(boxId);
			dispatch("notifications/success", `Box size ${state.boxSizes[boxId - 1].name} added`, { root: true });
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async removeBoxSizes({ commit, dispatch, state }, boxId: number) {
		try {
			commit("loading");
			await OrganisationsApi.removeBoxSizes(boxId);
			dispatch("notifications/success", `Box size ${state.boxSizes[boxId - 1].name} removed`, { root: true });
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async fetchAllowedBoxSizes({ commit, dispatch }) {
		try {
			commit("loading");
			const result = await OrganisationsApi.fetchAllowedBoxSizes();
			let results: Array<number> = [];
			result.data.forEach((size: BoxSizes) => {
				results[size.id - 1] = size.id;
			});
			commit("setAllowedSizes", results);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async changeOrderStatus(
		{ commit, dispatch },
		data: { order: Order; statusIds: Array<number>; paymentStatus: Array<string>; orgsearch?: string; params?: any }
	) {
		const getNextOrderStatus = (order: Order): CurrentOrderStatus => {
			switch (order.status.id) {
				case 2:
					return "accept";
				case 3:
					return "ready";
				case 5:
					if (order.deliveryType === "DELIVERY") return "in_transit";
					else return "collected";
				case 6:
					return "delivered";
				default:
					throw "No Such Status Id";
			}
		};

		try {
			commit("loading");
			await OrganisationsApi.changeOrderStatus(data.order.id, getNextOrderStatus(data.order), data.params);
			commit("notifications/success", `Order ${data.order.id} moved to ${getNextOrderStatus(data.order)}`, { root: true });
			dispatch("fetchOrdersByStatus", data);
			dispatch("fetchOrder", data.order.id);
			if (this.state.organisations.selectedOrganisation != null) {
				dispatch("fetchOrderStatusOverview", {
					organisationId: this.state.organisations.selectedOrganisation.id,
					paymentStatus: data.paymentStatus,
					orgsearch: data.orgsearch
				});
			}
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	async moveOrderBackwards({ commit, dispatch }, data: { order: Order; statusIds: Array<number>; paymentStatus: Array<string>; orgsearch?: string }) {
		const getPrevOrderStatus = (order: Order): CurrentOrderStatus => {
			switch (order.status.id) {
				case 3:
					return "pending";
				case 5:
					return "accept";
				case 6:
					return "ready";
				default:
					throw "No Such Status Id";
			}
		};

		try {
			commit("loading");
			await OrganisationsApi.changeOrderStatus(data.order.id, getPrevOrderStatus(data.order));
			commit("notifications/success", `Order ${data.order.id} moved to ${getPrevOrderStatus(data.order)}`, { root: true });
			dispatch("fetchOrdersByStatus", data);
			dispatch("fetchOrder", data.order.id);
			if (this.state.organisations.selectedOrganisation != null) {
				dispatch("fetchOrderStatusOverview", {
					organisationId: this.state.organisations.selectedOrganisation.id,
					paymentStatus: data.paymentStatus,
					orgsearch: data.orgsearch
				});
			}
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	async updateOrderItem({ commit, dispatch }, orderItemUpdateObj: { orderItem: any; orderId: number }) {
		try {
			commit("loading");
			const cleanUpdateObj = JSON.parse(JSON.stringify(orderItemUpdateObj.orderItem));
			cleanUpdateObj.id = orderItemUpdateObj.orderItem.detail.id;
			delete cleanUpdateObj.unitPrice;
			delete cleanUpdateObj.markup;
			delete cleanUpdateObj.linePrice;
			delete cleanUpdateObj.status;
			delete cleanUpdateObj.detail;
			delete cleanUpdateObj.extras;
			delete cleanUpdateObj.options;
			delete cleanUpdateObj.instorePaymentRequired;

			// cleanUpdateObj.detail = {options}
			await OrganisationsApi.updateOrderItem(orderItemUpdateObj.orderId, orderItemUpdateObj.orderItem.id, cleanUpdateObj);
			dispatch("fetchOrder", orderItemUpdateObj.orderId);
			commit("success", `Order has been updated`);
		} catch (error) {
			commit("error", error.response.data.message);
			Utils.logSentry(error);
		}
	},

	async addOrderGRN({ commit, dispatch }, data: { orderId: number; grn: string }) {
		try {
			commit("loading");
			await OrganisationsApi.addOrderGrn(data.orderId, data.grn);
			dispatch("fetchOrder", data.orderId);
			commit("notifications/success", `Order ${data.orderId} grn has been updated`, { root: true });
		} catch (error) {
			commit("notifications/error", error, { root: true });
			commit("loading", false);
		}
	},

	async declineOrder({ commit, dispatch }, data: { order: Order; statusIds: Array<number>; paymentStatus?: Array<string>; orgsearch?: string }) {
		try {
			commit("loading");
			await OrganisationsApi.declineOrder(data.order.id);
			dispatch("fetchOrdersByStatus", data);
			dispatch("fetchOrder", data.order.id);
			commit("notifications/success", `Order ${data.order.id} has been declined`, { root: true });
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
			commit("loading", false);
		}
	},

	refresh({ commit }) {
		commit("paginate", { ...pagingDefault });
	},

	paginate({ commit }, pagination) {
		commit("paginate", pagination);
	},

	setFilters({ commit }, filters: Filters) {
		// Axios does not support null parameters
		if (filters.fromDate === null) filters.fromDate = "";
		if (filters.toDate === null) filters.toDate = "";
		commit("setFilters", filters);
	},

	async getTransactions({ commit, state }, transactObj: transactionsFetchParams) {
		try {
			for (const item in transactObj) {
				if (!transactObj[item]) transactObj[item] = undefined;
			}
			commit("loading");
			const result = await OrganisationsApi.fetchTransaction(state.pagination, transactObj);
			commit("setTransactions", result.data.results);
			commit("setTotalItems", result.data.count);
		} catch (error) {
			commit("notifications/error", error, { root: true });
			commit("loading");
		}
	},

	async updateDeliveryRestrictions({ commit, dispatch }, restrictions: DeliveryRestrictions) {
		try {
			commit("loading");
			const result = await OrganisationsApi.updateDeliveryRestrictions(restrictions);
			dispatch("notifications/success", "Delivery Restrictions Updated", {
				root: true
			});
			commit("setDeliveryRestrictions", result.data);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	}
};
