import { GetterTree } from "vuex";
import { RootState } from "@/store/types";
import { ActiveOrdersState, isOrderActionable } from "@/store/organisations/activeOrders/types";

export const getters: GetterTree<ActiveOrdersState, RootState> = {
	ordersByStatus(state) {
		return (orderStatusId: number, paymentStatus?: "PAID" | "UNPAID", orgId?: number) => {
			if (!state.ordersByStatus[orderStatusId]) return [];
			return state.ordersByStatus[orderStatusId].orders;
		};
	},

	activeOrdersStatus(state) {
		return state.orderStatusses.filter(statusItem => {
			for (let status of state.activeOrderIds) if (status === statusItem.id) return true;
		});
	},

	orderStatusColor() {
		return (orderStatusId: number) => {
			switch (orderStatusId) {
				case 1:
				case 6:
					return "#33404D";
				case 12:
					return "primary";
				case 2:
					return "light-blue";
				case 7:
					return "accent";
				case 3:
					return "green";
				case 5:
					return "orange";
				case 8:
					return "success";
				case 4:
				case 9:
					return "error";
			}
		};
	},

	isOrderActionable() {
		return (orderStatusId: number) => {
			return isOrderActionable(orderStatusId);
		};
	},

	deliverySettings(state) {
		return state.deliverySettings;
	},

	deliveryRestrictions(state) {
		return state.deliveryRestrictions;
	},

	getAllowedBoxSizes(state) {
		let allowedSizes = state.allowedBoxSizes.filter((size: any) => {
			return size != null;
		});

		return allowedSizes;
	},

	allowedBoxSizesArray(state) {
		return state.allowedBoxSizes;
	},

	getSelectedOrder(state) {
		return state.selectedOrder;
	}
};
