import Axios from "axios";
import { utils } from "./http-common";

export interface ConnectionsBody {
	page?: number;
	pagesize?: number;
	status?: ConnectionsStatus;
	showDisabled?: boolean;
	organisationType?: number;
	search?: string;
}

export type ConnectionsStatus = "PENDING" | "CONNECTED" | "DECLINED" | undefined;

export async function fetchCurrentConnections(orgId: number, body: ConnectionsBody) {
	return Axios.get(utils.generateUrl(`organisations/${orgId}/connections`), {
		params: body,
		headers: await utils.generateHeader()
	});
}

export async function deleteConnection(connectionId: number) {
	return Axios.delete(utils.generateUrl(`connections/${connectionId}`), {
		headers: await utils.generateHeader()
	});
}

export async function createConnection(toOrganisationId: number, fromOrganisationId: number, typeCode = "M") {
	return Axios.post(
		utils.generateUrl("connections"),
		{ toOrganisationId, fromOrganisationId, typeCode },
		{
			headers: await utils.generateHeader()
		}
	);
}

export async function createNewConnection(organisationId: number, toOrganisationId: number) {
	return Axios.put(
		utils.generateUrl(`organisations/${organisationId}/sell`),
		{ toOrganisationId },
		{
			headers: await utils.generateHeader()
		}
	);
}

export async function fetchPossibleConnections(orgId: number, body: ConnectionsBody) {
	/* organisation/{id}/connection/possible */
	return Axios.get(utils.generateUrl(`organisations/${orgId}/connections/possible`), {
		params: body,
		headers: await utils.generateHeader()
	});
}

export async function acceptConnection(orgId: number, connectionId: number) {
	return Axios.put(
		utils.generateUrl(`connections/${connectionId}/organisation/${orgId}/accept`),
		{},
		{
			headers: await utils.generateHeader()
		}
	);
}

export async function declineConnection(orgId: number, connectionId: number) {
	return Axios.put(
		utils.generateUrl(`connections/${connectionId}/organisation/${orgId}/decline`),
		{},
		{
			headers: await utils.generateHeader()
		}
	);
}

export async function fetchConnectionTypes() {
	return Axios.get(utils.generateUrl("organisations/types"), {
		headers: await utils.generateHeader()
	});
}
