import { Module, ActionTree, GetterTree, MutationTree } from "vuex";
import { statusFactory, Status } from "@/store/utils";
import { RootState } from "@/store/types";
import { AddressApi } from "@/api/address-api";

export type Address = {
	id: number;
	name: string;
	country: string;
	province: string;
	city: string;
	suburb: string;
	complexName: string;
	unitNumber: string;
	streetName: string;
	streetNumber: string;
	postalCode: string;
	note: string;
	latitude: number;
	longitude: number;
	isDisabled: boolean;
	isPrimary: boolean;
	isFavourite: boolean;
	isBilling: boolean;
	isCollection: boolean;
	isDelivery: boolean;
	userId: string;
	businessId: number;
};

export interface AddressesState {
	addresses: Address[];
	selectedAddress?: Address;
	geoSearchAddresses: any;
	status: Status;
}

export const state: AddressesState = {
	addresses: [],
	selectedAddress: undefined,
	geoSearchAddresses: [],
	status: statusFactory()
};

const namespaced: boolean = true;

export const getters: GetterTree<AddressesState, RootState> = {};

export const actions: ActionTree<AddressesState, RootState> = {
	async fetchAddresses({ commit, dispatch, rootState }) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");
			commit("loading");
			const response = await AddressApi.fetchAddresses(rootState.organisations.selectedOrganisation.id);
			commit("setAddresses", response.data.results);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async fetchAddress({ commit, dispatch, rootState }, addressId: number) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");
			commit("loading");
			const orgId = rootState.organisations.selectedOrganisation.id;
			const response = await AddressApi.fetchAddress(orgId, addressId);
			commit("setSelectedAddress", response.data);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async createAddress({ commit, dispatch, rootState }, address: Address) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");
			commit("loading");
			await AddressApi.createAddress(rootState.organisations.selectedOrganisation.id, address);
			commit("notifications/success", "New Address Created", { root: true });
			commit("success");
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async updateAddress({ commit, dispatch, rootState }, address: Address) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");
			commit("loading");
			const orgId = rootState.organisations.selectedOrganisation.id;
			const addressData = JSON.parse(JSON.stringify(address));
			const addressId = addressData.id;
			delete addressData.id;
			delete addressData.businessId;
			await AddressApi.updateAddress(orgId, addressId, addressData);
			commit("notifications/success", "Address Updated", { root: true });
			dispatch("fetchAddresses");
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
			dispatch("fetchAddresses");
		}
	},

	async setSelectedAddress({ commit, dispatch, rootState }, address: Address) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");
			commit("loading");
			commit("setSelectedAddress", address);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async clearSelectedAddress({ commit }) {
		commit("setSelectedAddress", undefined);
	},

	async searchAddress({ commit, dispatch }, searchText: string) {
		try {
			commit("loading");
			const response = await AddressApi.searchAddress(searchText);
			const responseView = response.data.Response.View;
			const result: any = [];
			for (let view of responseView) {
				if (view.ViewId === 0) {
					result.push(...view.Result);
				}
			}
			commit("setSearchedAddresses", result);
			return true;
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
			commit("loading", false);
		}
	},

	async deleteAddress({ commit, dispatch, rootState }, address) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");
			const orgId = rootState.organisations.selectedOrganisation.id;
			commit("loading");
			await AddressApi.deleteAddress(orgId, address.id);
			commit("notifications/success", "Address Deleted", { root: true });
			dispatch("fetchAddresses");
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
			commit("loading", false);
		}
	},

	async clearSuccess({ commit }) {
		commit("success", false);
	}
};

export const mutations: MutationTree<AddressesState> = {
	setAddresses(state, payload) {
		state.addresses = payload;
		state.status.loading = false;
	},
	setSelectedAddress(state, payload) {
		state.selectedAddress = payload;
		state.status.loading = false;
	},
	setSearchedAddresses(state, payload) {
		state.geoSearchAddresses = payload;
		state.status.loading = false;
	},

	success(state, payload = true) {
		state.status.success = payload;
	},

	loading(state, payload = true) {
		state.status.loading = payload;
	}
};

export const addresses: Module<AddressesState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};

export default addresses;
