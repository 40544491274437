import { Address } from "../addresses";

export interface Transaction {
	accountId: number;
	accountName: string;
	accountType: string;
	amount: number;
	balance: number;
	dateTime: Date;
	debitorCreditor: string;
	issuedByUser: string;
	status: string;
	transactionId: number;
	transactionType: string;
}

export interface OrderStatus {
	id: number;
	name: string;
	description: string;
}

export interface Order {
	id: number;
	grn: string;
	deliveryType: "DELIVERY" | "COLLECTION";
	shortReference: string;
	dateCreated: Date;
	status: OrderStatus;
	paymentStatus: "PAID" | "UNPAID";
	currentPaymentStatus: "PAID" | "UNPAID";
	organisation: { id: number };
	items: { detail: { organisation: { id: number } } }[];
}

export interface Paging {
	page: number;
	itemsPerPage: number;
	sortBy: string | Array<string>;
	descending: boolean;
	totalItems?: number;
	filters: { [key: string]: any };
}

export interface Filters {
	sort?: "date+" | "date-";
	fromDate?: string;
	toDate?: string;
	orderstatus?: string[] | number[];
	paymentstatus?: string[];
	orgsearch?: string;
	salesOrder?: boolean;
}

export interface ParcelData {
	boxSizeId: number;
	count: number;
}

export interface BoxSizes {
	id: number;
	name: string;
	description: string;
}

export interface Provider {
	id: number;
	name?: string;
}

export interface Providers {
	fee?: number;
	warehouseId?: string;
	provider?: Provider;
}

export interface DeliveryPlace {
	country: "string";
	province: "string";
	city?: "string";
	suburb?: "string";
	street?: "string";
}

export interface DeliveryRestrictions {
	distance: number;
	places?: Array<DeliveryPlace>;
}

export type orderStatusObj = { name: orderStatus; actionable: boolean };
export type orderStatus = "New" | "Pending" | "Accepted" | "Ready" | "Collected" | "Delivered" | "Completed" | "Cancelled" | "Rejected" | "In Transit";

export interface OrderByStatus {
	[orderStatusId: number]: {
		orders: Order[];
		pagination?: Paging;
	};
}
export function getOrderStatusName(orderStatusId: number, actionable?: boolean): orderStatusObj | boolean {
	let obj: orderStatusObj;
	switch (orderStatusId) {
		case 1:
			obj = { name: "New", actionable: true };
			break;
		case 2:
			obj = { name: "Pending", actionable: true };
			break;
		case 3:
			obj = { name: "Accepted", actionable: true };
			break;
		case 4:
			obj = { name: "Cancelled", actionable: false };
			break;
		case 5:
			obj = { name: "Ready", actionable: true };
			break;
		case 6:
			obj = { name: "Collected", actionable: false };
			break;
		case 7:
			obj = { name: "Delivered", actionable: false };
			break;
		case 8:
			obj = { name: "Completed", actionable: false };
			break;
		case 9:
			obj = { name: "Rejected", actionable: false };
			break;
		case 12:
			obj = { name: "In Transit", actionable: false };
			break;
		default:
			throw Error(`Order status(${orderStatusId}) not recognised!`);
	}

	if (actionable) return obj.actionable;
	else return obj;
}

export function isOrderActionable(orderStatusId: number): boolean {
	const actionable = getOrderStatusName(orderStatusId, true);
	return typeof actionable === "boolean" ? actionable : false;
}

export interface ActiveOrdersState {
	orders: Order[];
	selectedOrder: Order;
	orderAddress: Address;
	orderTransactions?: Transaction[];
	activeOrderIds: number[];
	ordersByStatus: OrderByStatus;
	orderStatusses: OrderStatus[];
	orderStatusOverview: any;
	providers: Array<Providers>;
	deliverySettings: Providers;
	deliveryRestrictions: DeliveryRestrictions;
	boxSizes: Array<BoxSizes>;
	allowedBoxSizes: Array<BoxSizes>;
	pagination: Paging;
	totalItems: number;
	filters: Filters;
	loading: boolean;
}
