import { utils } from "./http-common";
import Axios from "axios";
import { store } from "@/store";

export type AccountKind = "withdraw" | "deposit";
export type AccountType = "topup" | "transfer" | "payment" | "settlement";

export class AccountsAPi {
	static async getAccounts() {
		return Axios.get(utils.generateUrl("marketplace/accounts"), {
			headers: utils.generateHeader()
		});
	}

	static async fetchAccounts(organisationId: number, kind?: AccountKind, tx_type?: AccountType) {
		return Axios.get(utils.generateUrl(`organisations/${organisationId}/accounts`), {
			params: {
				kind,
				tx_type
			},
			headers: await utils.generateHeader()
		});
	}

	static async fetchAccount(accountId: number) {
		return Axios.get(utils.generateUrl(`accounts/${accountId}`), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchPermissions(organisationId: number) {
		return Axios.get(utils.generateUrl(`organisations/${organisationId}/accounts/permissions`), {
			headers: await utils.generateHeader()
		});
	}

	static async savePermissions(organisationId: number, data: { depositIntoBankAccount: boolean }) {
		return Axios.put(utils.generateUrl(`organisations/${organisationId}/accounts/permissions`), data, {
			headers: await utils.generateHeader()
		});
	}

	static async fetchAccountTypes() {
		return Axios.get(utils.generateUrl("accounts/types?filter=business"), {
			headers: await utils.generateHeader()
		});
	}

	static async addAccount(rawAccountData: any, organisationId: number) {
		return Axios.post(utils.generateUrl(`organisations/${organisationId}/accounts`), rawAccountData, {
			headers: await utils.generateHeader()
		});
	}

	static async updateAccount(accountId: number, accountObj: any) {
		return Axios.put(utils.generateUrl(`accounts/${accountId}`), accountObj, {
			headers: await utils.generateHeader()
		});
	}

	static async deleteAccount(accountId: number) {
		return Axios.delete(utils.generateUrl(`accounts/${accountId}`), {
			headers: await utils.generateHeader()
		});
	}
}
