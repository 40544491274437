import { Module, ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from "@/store/types";
import { users } from "@/store/organisations/users.ts";
import { settings } from "@/store/organisations/settings.ts";
import { accounts } from "@/store/organisations/accounts.ts";
import { addresses } from "@/store/organisations/addresses.ts";
import { connections } from "@/store/organisations/connections.ts";
import { activeOrders } from "@/store/organisations/activeOrders/index";
import { User } from "../user/types";
import { Paging } from "../utils";
import { OrganisationsApi, UpdateOrganisationContactObject, OrganisationsParams } from "@/api/organisation-api";

export interface Organisation {
	id: number;
	name: string;
	code: string;
	profile: {
		contactNumber: string;
		contactPerson: User;
		id: string;
		firstName: string;
	};
	toOrganisation: any;
	fromOrganisation: any;
	types: any[];
	address: any;
	requestedBy: any;
	status: any;
	dateCreated: any;
}

export interface OrganisationState {
	organisations: Organisation[];
	selectedOrganisation?: Organisation;
	createdOrganisationId?: number;
	searchResults: Organisation[];
	searching: boolean;
	pagination: Paging;
	loading: boolean;
	success: boolean;
}

export const state: OrganisationState = {
	organisations: [],
	searchResults: [],
	searching: false,
	pagination: {
		page: 1,
		itemsPerPage: 5,
		sortBy: "",
		totalItems: 0,
		descending: false,
		filters: {}
	},
	selectedOrganisation: undefined,
	createdOrganisationId: undefined,
	loading: false,
	success: false // This is merely for the shop creation
};

const namespaced: boolean = true;

export const actions: ActionTree<OrganisationState, RootState> = {
	async createOrganisation({ commit, dispatch }, shopName) {
		try {
			commit("loading");
			const results = await OrganisationsApi.createOrganisation(shopName);

			const locationHeader = results.headers.location;
			const lastslashindex = locationHeader.lastIndexOf("/");
			const createOrganisationId = +locationHeader.substring(lastslashindex + 1);

			commit("updateCreatedOrgId", createOrganisationId);
			commit("success");
			dispatch("notifications/success", `Shop Created: ${shopName.name}`, { root: true });
		} catch (error) {
			dispatch("notifications/error", `Organisation name ${shopName.name} already exists`, { root: true });
			commit("loading", false);
		}
	},

	async updateOrganisation({ commit, dispatch }, updateObj) {
		try {
			commit("loading");
			const organisationId = updateObj.id;
			delete updateObj.id;
			delete updateObj.code;
			await OrganisationsApi.updateOrganisation(organisationId, updateObj);

			dispatch("fetchOrganisationById", organisationId);
			commit("notifications/success", `Shop Updated: ${updateObj.name}`, { root: true });
		} catch (error) {
			// if (error.response.data) sentry.log(error.response.data)
			commit("notifications/error", `Failed to update organisation: ${updateObj.name}`, { root: true });
		}
	},

	async fetchOrganisations({ commit }, data: { pagination: Paging; params: OrganisationsParams }) {
		try {
			commit("loading");
			const results = await OrganisationsApi.fetchOrganisations(data.pagination.page, data.pagination.itemsPerPage, data.params);
			commit("setOrganisations", results.data.results);
			commit("setTotal", results.data.count);
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	async fetchOrganisationById({ commit, dispatch }, orgId: number) {
		try {
			commit("loading");
			const results = await OrganisationsApi.fetchOrganisationById(orgId);
			commit("setSelectedOrganisation", results.data);
		} catch (error) {
			commit("setSelectedOrganisation", null);
			dispatch("notifications/error", error, { root: true });
		}
	},

	async findOrganisationByUserId({ commit, dispatch }, userId) {
		try {
			commit("loading");
			const results = await OrganisationsApi.findOrganisationByUserId(userId);
			commit("setOrganisations", results.data.results);
		} catch (error) {
			commit("setOrganisations", null);
			dispatch("notifications/error", error, { root: true });
		}
	},

	async searchOrganisation({ commit }, searchVal: string) {
		try {
			commit("searching");
			const results = await OrganisationsApi.getOrganisationsBySearch(searchVal);
			commit("setSearchResults", results.data.results);
		} catch (error) {
			commit("notifications/error");
		}
	},

	async removeGroupFromUser({ commit, dispatch }, addUserObject: { organisationId: number; user: User; groups: any }) {
		try {
			commit("loading");
			const addUserApiObj = { organisationId: addUserObject.organisationId, userId: addUserObject.user.id };
			if (!addUserObject.groups || addUserObject.groups.length < 1) addUserObject.groups = [200];

			if (addUserObject.groups.includes(200)) await OrganisationsApi.removeGroupFromUser({ groupId: 200, ...addUserApiObj });
			if (addUserObject.groups.includes(5)) await OrganisationsApi.removeGroupFromUser({ groupId: 5, ...addUserApiObj });
			if (addUserObject.groups.includes(2)) await OrganisationsApi.removeGroupFromUser({ groupId: 2, ...addUserApiObj });
			if (addUserObject.groups.includes(3)) await OrganisationsApi.removeGroupFromUser({ groupId: 3, ...addUserApiObj });

			dispatch("users/fetchUsers");
			commit("notifications/success", `User Roles Updated.`, { root: true });
		} catch (error) {
			commit("notifications/success", error.message, { root: true });
		}
	},

	async addUserToOrganisation({ commit, dispatch }, addUserObject: { organisationId: number; user: User; groups: any; successMessage?: string }) {
		try {
			commit("loading");
			const addUserApiObj = { organisationId: addUserObject.organisationId, userId: addUserObject.user.id };
			if (!addUserObject.groups || addUserObject.groups.length < 1) addUserObject.groups = [200];

			if (addUserObject.groups.includes(200)) await OrganisationsApi.addUserToOrganisation({ groupId: 200, ...addUserApiObj });
			if (addUserObject.groups.includes(5)) await OrganisationsApi.addUserToOrganisation({ groupId: 5, ...addUserApiObj });
			if (addUserObject.groups.includes(2)) await OrganisationsApi.addUserToOrganisation({ groupId: 2, ...addUserApiObj });
			if (addUserObject.groups.includes(3)) await OrganisationsApi.addUserToOrganisation({ groupId: 3, ...addUserApiObj });

			dispatch("users/fetchUsers");
			commit("notifications/success", addUserObject.successMessage || `New user added!`, { root: true });
		} catch (error) {
			commit("notifications/error", { root: true });
		}
	},

	async addPrimaryUserToOrganisation({ commit, dispatch }, addUserObject: { organisationId: number; user: User }) {
		try {
			commit("loading");

			const organisationContactObj: UpdateOrganisationContactObject = {
				contactPerson: {
					id: addUserObject.user.id,
					profile: addUserObject.user.profile
				}
			};
			await OrganisationsApi.updateOrganisationContact(addUserObject.organisationId, organisationContactObj);

			dispatch("fetchOrganisationById", addUserObject.organisationId);
			commit("notifications/success", "Primary User Update", { root: true });
		} catch (error) {
			commit("notifications/error", error.message, { root: true });
		}
	},

	// This Will add user to organisation give him full right and make him primart
	async addUserToOrganisationWithGroups({ commit }, addUserObject: { organisationId: number; user: User }) {
		try {
			commit("loading");
			const addUserApiObj = { organisationId: addUserObject.organisationId, userId: addUserObject.user.id };

			await OrganisationsApi.addUserToOrganisation({ groupId: 200, ...addUserApiObj });
			await OrganisationsApi.addUserToOrganisation({ groupId: 5, ...addUserApiObj });
			await OrganisationsApi.addUserToOrganisation({ groupId: 2, ...addUserApiObj });
			await OrganisationsApi.addUserToOrganisation({ groupId: 3, ...addUserApiObj });

			const organisationContactObj: UpdateOrganisationContactObject = {
				contactPerson: {
					id: addUserObject.user.id,
					profile: addUserObject.user.profile
				}
			};
			await OrganisationsApi.updateOrganisationContact(addUserObject.organisationId, organisationContactObj);

			commit("notifications/success", "Shop created and user assigned!", { root: true });
			commit("success");
			commit("loading", false);
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	clearSuccess({ commit }) {
		commit("success", false);
	},

	paginate({ commit }, pagination) {
		commit("paginate", pagination);
	}
};

export const getters: GetterTree<OrganisationState, RootState> = {
	organisationDetails(state) {
		if (!state.selectedOrganisation) return {};
		const returnObj: any = state.selectedOrganisation;
		returnObj["profile.contactNumber"] = state.selectedOrganisation.profile.contactNumber;
		return returnObj;
	}
};

export const mutations: MutationTree<OrganisationState> = {
	setOrganisations(state, payload: Organisation[]) {
		state.organisations = payload;
		state.loading = false;
	},
	setSelectedOrganisation(state, payload) {
		state.selectedOrganisation = payload;
		state.loading = false;
	},
	// This is the id of the last created store
	updateCreatedOrgId(state, payload) {
		state.createdOrganisationId = payload;
		state.loading = false;
	},
	paginate(state, payload) {
		state.pagination = payload;
	},
	setTotal(state, count: number) {
		state.pagination.totalItems = count;
	},
	loading(state, payload = true) {
		state.loading = payload;
	},
	success(state, payload = true) {
		state.success = payload;
	},
	/* Search for organisations */
	setSearchResults(state, payload: Organisation[]) {
		state.searching = false;
		state.searchResults = payload;
	},
	searching(state) {
		state.searching = true;
	}
};

export const organisations: Module<OrganisationState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations,
	modules: { users, settings, accounts, addresses, connections, activeOrders }
};
