import { utils } from "@/api/http-common";
import { Utils } from "@/utils";
import Axios from "axios";
import { Organisation, state } from "@/store/organisations";
import { DeliveryRestrictions, Filters, Paging, Providers } from "@/store/organisations/activeOrders/types";

export interface ShopCreateObject {
	name: string; // Shop Name
}

export interface AddUserToShopObject {
	organisationId: number;
	groupId: number;
	userId: string;
}

export interface OrganisationsParams {
	email?: string;
	type?: number;
	search?: string;
}

export interface UpdateOrganisationContactObject {
	contactPerson: {
		id: string;
		profile: {
			firstName: string;
			surname: string;
			email: string;
			nickname: string;
			cellphoneNumber: string;
			sex: string;
			image: string;
			imageThumbnail: string;
		};
	};
	contactNumber?: string;
	notificationSourceEmail?: string;
}

export interface transactionsFetchParams {
	[key: string]: number | string | unknown;
	account_id?: number;
	order_id?: number;
}

export function parseSort(paging: Paging) {
	if (paging.sortBy && paging.sortBy.length > 0) return `${paging.sortBy}.${paging.descending ? "desc" : "asc"}`;
	else return undefined;
}

export type CurrentOrderStatus = "collected" | "ready" | "delivered" | "declined" | "in_transit" | "accept" | "cancelled" | "pending";
export class OrganisationsApi {
	static async fetchOrganisations(page: number, pagesize: number, params: OrganisationsParams) {
		return Axios.get(utils.generateUrl(`organisations/all`), {
			params: {
				page,
				pagesize,
				...params
			},
			headers: await utils.generateHeader()
		});
	}

	static async fetchOrganisationTypes() {
		return Axios.get(utils.generateUrl(`organisations/types`), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchOrganisationById(orgId: number) {
		return Axios.get(utils.generateUrl(`organisations/${orgId}`), {
			headers: await utils.generateHeader()
		});
	}

	static async findOrganisationByUserId(userId: number) {
		return Axios.get(utils.generateUrl(`organisations/user/${userId}`), {
			headers: await utils.generateHeader()
		});
	}

	static async getOrganisationsBySearch(searchVal: string) {
		return Axios.get(utils.generateUrl(`organisations/search?name=${searchVal}`), {
			headers: await utils.generateHeader()
		});
	}

	static async createOrganisation(shopCreateObject: any) {
		const shopObject = {
			code: Utils.generateCode(6),
			timezoneOffset: 2,
			registeredDirectly: true,
			billingProfile: {
				businessName: "",
				vatNumber: "",
				contactName: "",
				phoneNumber: "",
				email: "",
				address: ""
			},
			...shopCreateObject
		};

		return Axios.post(
			utils.generateUrl(`organisations`),

			{
				...shopObject
			},
			{
				params: {
					admin: true
				},
				headers: await utils.generateHeader()
			}
		);
	}

	static async removeGroupFromUser(addUserToOrgObj: AddUserToShopObject) {
		return Axios.delete(utils.generateUrl(`authorization/organisation/${addUserToOrgObj.organisationId}/group/${addUserToOrgObj.groupId}/members`), {
			headers: await utils.generateHeader(),
			data: [addUserToOrgObj.userId]
		});
	}

	static async addUserToOrganisation(addUserToOrgObj: AddUserToShopObject) {
		return Axios.post(
			utils.generateUrl(`authorization/organisation/${addUserToOrgObj.organisationId}/group/${addUserToOrgObj.groupId}/members`),
			[addUserToOrgObj.userId],
			{
				headers: await utils.generateHeader()
			}
		);
	}

	static async updateOrganisationContact(organisationId: number, addUserToOrgObj: UpdateOrganisationContactObject) {
		return Axios.put(
			utils.generateUrl(`organisations/${organisationId}/detail`),
			{ ...addUserToOrgObj },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	static async updateOrganisation(organisationId: number, updateObj: Organisation) {
		return Axios.put(
			utils.generateUrl(`organisations/${organisationId}`),
			{ ...updateObj },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	static async fetchUsers(orgId: number) {
		return Axios.get(utils.generateUrl(`authorization/organisation/${orgId}/members`), {
			headers: await utils.generateHeader()
		});
	}

	private static transformRequestOptions(params: any) {
		let options = "";
		for (const key in params) {
			if (typeof params[key] !== "object") {
				if (!params[key]) continue;
				options += `${key}=${params[key]}&`;
			} else if (typeof params[key] === "object" && params[key].length) {
				params[key].forEach((el: any) => {
					options += `${key}=${el}&`;
				});
			}
		}
		return options ? options.slice(0, -1) : options;
	}

	public static async updateOrderItem(orderId: number, orderItemId: number, orderItemBody: any) {
		return Axios.put(
			utils.generateUrl(`orders/${orderId}/update/item/${orderItemId}`),
			{ ...orderItemBody },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	public static async addOrderGrn(orderId: number, grn: string) {
		return Axios.put(
			utils.generateUrl(`orders/${orderId}/grn`),
			{ grn },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	public static async fetchOrders(paging: Paging, filters: Filters) {
		const orgId = state.selectedOrganisation ? state.selectedOrganisation.id : -1;
		return Axios.get(utils.generateUrl(`orders/organisation/${orgId}/summary`), {
			params: {
				page: paging.page,
				pagesize: paging.itemsPerPage,
				...filters
			},
			paramsSerializer: params => this.transformRequestOptions(params),
			headers: await utils.generateHeader()
		});
	}

	public static async fetchOrder(orderId: number) {
		return Axios.get(utils.generateUrl(`orders/${orderId}`), {
			headers: await utils.generateHeader()
		});
	}

	public static async fetchOrderPickupAddress() {
		const orgId = state.selectedOrganisation ? state.selectedOrganisation.id : -1;
		return Axios.get(utils.generateUrl(`organisations/${orgId}/locations`), {
			headers: await utils.generateHeader()
		});
	}

	public static async fetchTransaction(paging: any, otherParams: transactionsFetchParams) {
		const orgId = state.selectedOrganisation ? state.selectedOrganisation.id : -1;
		return Axios.get(utils.generateUrl(`v2/transactions/history`), {
			params: {
				org_id: orgId,
				page_number: paging.page,
				page_size: paging.itemsPerPage,
				sort: parseSort(paging),
				...otherParams
			},
			headers: await utils.generateHeader()
		});
	}

	// TODO: This if statement is one level to low. It should live in the middleware
	public static async changeOrderStatus(id: number, statusType: CurrentOrderStatus, params: any = { message: "" }) {
		const orgId = state.selectedOrganisation ? state.selectedOrganisation.id : -1;
		if (statusType === "delivered" || statusType === "pending") {
			return Axios.put(
				utils.generateUrl(`orders/${id}/status/${statusType}`),
				{ ...params },
				{
					headers: await utils.generateHeader()
				}
			);
		} else {
			return Axios.put(
				utils.generateUrl(`orders/${id}/organisation/${orgId}/status/${statusType}`),
				{ ...params },
				{
					headers: await utils.generateHeader()
				}
			);
		}
	}

	public static async declineOrder(orderId: number, message = "") {
		const orgId = state.selectedOrganisation ? state.selectedOrganisation.id : -1;

		return Axios.put(
			utils.generateUrl(`orders/${orderId}/organisation/${orgId}/status/declined`),
			{ message },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	public static async fetchOrderStatusses() {
		return Axios.get(utils.generateUrl(`orderstatus`), {
			headers: await utils.generateHeader()
		});
	}

	public static async fetchOrderStatusOverview(organisationId: number, paymentStatus?: Array<string>, orgsearch?: string) {
		let status = "";

		if (paymentStatus != null) {
			paymentStatus.forEach((item: string) => {
				status += `&paymentstatus=${item}`;
			});
		}

		if (orgsearch != null || orgsearch === "") {
			status += `&orgsearch=${orgsearch}`;
		}

		return Axios.get(
			utils.generateUrl(`orders/organisation/${organisationId}/summary/count?orderstatus=2&orderstatus=3&orderstatus=5&orderstatus=6${status}`),
			{
				headers: await utils.generateHeader()
			}
		);
	}

	public static async fetchDeliverySettings() {
		const orgId = state.selectedOrganisation ? state.selectedOrganisation.id : -1;
		return Axios.get(utils.generateUrl(`deliveries/organisation/${orgId}/settings`), {
			headers: await utils.generateHeader()
		});
	}

	public static async fetchDeliveryRestrictions() {
		const orgId = state.selectedOrganisation ? state.selectedOrganisation.id : -1;
		return Axios.get(utils.generateUrl(`organisations/${orgId}/delivery/restriction`), {
			headers: await utils.generateHeader()
		});
	}

	public static async updateDeliveryFee(fee: number) {
		const orgId = state.selectedOrganisation ? state.selectedOrganisation.id : -1;
		return Axios.put(
			utils.generateUrl(`deliveries/organisation/${orgId}/settings`),
			{ fee },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	public static async updateRequireBoxes(requireBoxes: boolean) {
		const orgId = state.selectedOrganisation ? state.selectedOrganisation.id : -1;
		return Axios.put(
			utils.generateUrl(`deliveries/organisation/${orgId}/settings`),
			{ requireBoxes },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	public static async updateDeliveryProvider(provider: Providers) {
		const orgId = state.selectedOrganisation ? state.selectedOrganisation.id : -1;
		return Axios.put(
			utils.generateUrl(`deliveries/organisation/${orgId}/settings`),
			{ ...provider },
			{
				headers: await utils.generateHeader()
			}
		);
	}

	public static async fetchProviders() {
		return Axios.get(utils.generateUrl(`deliveries/providers`), {
			headers: await utils.generateHeader()
		});
	}

	public static async fetchBoxSizes() {
		return Axios.get(utils.generateUrl(`deliveries/boxes`), {
			headers: await utils.generateHeader()
		});
	}

	public static async fetchAllowedBoxSizes() {
		const orgId = state.selectedOrganisation ? state.selectedOrganisation.id : -1;
		return Axios.get(utils.generateUrl(`deliveries/organisation/${orgId}/boxes`), {
			headers: await utils.generateHeader()
		});
	}

	public static async addBoxSizes(boxId: number) {
		const orgId = state.selectedOrganisation ? state.selectedOrganisation.id : -1;
		return Axios.put(
			utils.generateUrl(`deliveries/organisation/${orgId}/boxes/${boxId}`),
			{},
			{
				headers: await utils.generateHeader()
			}
		);
	}

	public static async removeBoxSizes(boxId: number) {
		const orgId = state.selectedOrganisation ? state.selectedOrganisation.id : -1;
		return Axios.delete(utils.generateUrl(`deliveries/organisation/${orgId}/boxes/${boxId}`), {
			headers: await utils.generateHeader()
		});
	}

	public static async updateDeliveryRestrictions(restrictions: DeliveryRestrictions) {
		const orgId = state.selectedOrganisation ? state.selectedOrganisation.id : -1;
		return Axios.put(
			utils.generateUrl(`organisations/${orgId}/delivery/restriction`),
			{ ...restrictions },
			{
				headers: await utils.generateHeader()
			}
		);
	}
}
