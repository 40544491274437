import Axios from "axios";
import { utils } from "./http-common";
import { Address } from "@/store/organisations/addresses";

export class AddressApi {
	static async searchAddress(searchText: string, country = "ZAF") {
		if (!process.env.VUE_APP_GEO_API) throw Error("No Geo Api Spesified");
		return Axios.get(process.env.VUE_APP_GEO_API, {
			params: { country, app_id: process.env.VUE_APP_GEO_API_ID, app_code: process.env.VUE_APP_GEO_API_CODE, searchText }
		});
	}

	static async fetchAddresses(orgId: number) {
		return Axios.get(utils.generateUrl(`organisations/${orgId}/locations`), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchAddress(orgId: number, addressId: number) {
		return Axios.get(utils.generateUrl(`organisations/${orgId}/locations/${addressId}`), {
			headers: await utils.generateHeader()
		});
	}

	static async deleteAddress(orgId: number, addressId: number) {
		return Axios.delete(utils.generateUrl(`organisations/${orgId}/locations/${addressId}`), {
			headers: await utils.generateHeader()
		});
	}

	static async createAddress(orgId: number, address: Address) {
		return Axios.post(
			utils.generateUrl(`organisations/${orgId}/locations`),
			{ ...address },
			{
				headers: await utils.generateHeader()
			}
		);
	}
	static async updateAddress(orgId: number, addressId: number, address: Address) {
		return Axios.put(
			utils.generateUrl(`organisations/${orgId}/locations/${addressId}`),
			{ ...address },
			{
				headers: await utils.generateHeader()
			}
		);
	}
}
