import { Module, ActionTree, GetterTree, MutationTree } from "vuex";
import { statusFactory, Status } from "@/store/utils";
import { RootState } from "@/store/types";
import { AccountsAPi, AccountKind, AccountType as TxType } from "@/api/accounts-api";

export type Account = {
	id: number;
	holder: string;
	method: string;
	methodKey: string;
	name: string;
	properties: { key: string; value: string }[];
	validationStatus: any;
};

export type fieldType = "text" | "password" | "option";

export type AccountTypeAttribute = {
	description: "Peach payments card token";
	fieldType: fieldType;
	isRequired: boolean;
	key: string;
	label: string;
	validation: unknown;
};

export type AccountType = {
	key: string;
	label: string;
	attributes: AccountTypeAttribute[];
};

export type AccountTxType = { [type in TxType]: Account[] };

export interface AccountsState {
	accounts: Account[];
	accountsByType: AccountTxType;
	selectedAccount?: Account;
	accountTypes: AccountType[];
	status: Status;
}

export const state: AccountsState = {
	accounts: [],
	accountsByType: {
		settlement: [],
		transfer: [],
		payment: [],
		topup: []
	},
	selectedAccount: undefined,
	accountTypes: [],
	status: statusFactory()
};

const namespaced: boolean = true;

export const getters: GetterTree<AccountsState, RootState> = {};

export const actions: ActionTree<AccountsState, RootState> = {
	// TODO: We want to split this out into a different action: fetchAccountsByType
	async fetchAccounts({ commit, rootState }) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");
			commit("loading");
			const response = await AccountsAPi.fetchAccounts(rootState.organisations.selectedOrganisation.id);
			commit("setAccounts", response.data.results);
		} catch (error) {
			commit("notifications/error", error.response.data.message, { root: true });
		}
	},

	async fetchAccountsByType({ commit, rootState }, params: { kind: AccountKind; tx_type: TxType }) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");
			commit("loading");
			const response = await AccountsAPi.fetchAccounts(rootState.organisations.selectedOrganisation.id, params.kind, params.tx_type);
			commit("setAccountsByType", { results: response.data.results, type: params.tx_type });
		} catch (error) {
			commit("notifications/error", error.response.data.message, { root: true });
		}
	},

	async fetchAccount({ commit, rootState }, accountId: number) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");
			commit("loading");
			const response = await AccountsAPi.fetchAccount(accountId);
			commit("setSelectedAccount", response.data);
		} catch (error) {
			commit("notifications/error", error.response.data.message, { root: true });
		}
	},

	async addAccount({ commit, dispatch, rootState }, accountObj) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");
			commit("loading");
			await AccountsAPi.addAccount(accountObj, rootState.organisations.selectedOrganisation.id);
			dispatch("fetchAccounts");
			commit("notifications/success", "New Account Added", { root: true });
			return true;
		} catch (error) {
			commit("notifications/error", error.response.data.message, { root: true });
			return false;
		}
	},

	async updateAccount({ commit, dispatch, rootState, state }, accountObj) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");
			if (!state.selectedAccount) throw new Error("No Account Selected To Update!");
			commit("loading");
			await AccountsAPi.updateAccount(state.selectedAccount.id, accountObj);
			dispatch("fetchAccounts");
			commit("notifications/success", "Account Updated", { root: true });
		} catch (error) {
			commit("notifications/error", error.response.data.message, { root: true });
		}
	},

	async deleteAccount({ commit, dispatch, rootState, state }, accountId) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");
			commit("loading");
			await AccountsAPi.deleteAccount(accountId);
			dispatch("fetchAccounts");
			commit("notifications/success", "Account Deleted", { root: true });
		} catch (error) {
			commit("notifications/error", error.response.data.message, { root: true });
		}
	},

	async fetchAccountTypes({ commit }) {
		try {
			commit("loading");
			const response = await AccountsAPi.fetchAccountTypes();
			commit("setAccountTypes", response.data.results);
		} catch (error) {
			commit("notifications/error", error.message, { root: true });
		}
	}
};

export const mutations: MutationTree<AccountsState> = {
	setAccounts(state, accounts) {
		state.accounts = accounts;
		state.status.loading = false;
	},

	setAccountsByType(state, accountsObj: { results: Account[]; type: TxType }) {
		state.accountsByType[accountsObj.type] = accountsObj.results;
		state.status.loading = false;
	},

	setSelectedAccount(state, account) {
		state.selectedAccount = account;
		state.status.loading = false;
	},

	setAccountTypes(state, accounts) {
		state.accountTypes = accounts;
		state.status.loading = false;
	},

	success(state, payload) {
		state.status.success = payload || false;
		state.status.loading = false;
		state.status.error = false;
	},

	loading(state) {
		state.status.success = false;
		state.status.loading = true;
		state.status.error = false;
	},

	error(state, payload) {
		state.status.success = false;
		state.status.loading = false;
		state.status.error = payload || false;
	}
};

export const accounts: Module<AccountsState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};

export default accounts;
