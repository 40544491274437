import { Module, ActionTree, GetterTree, MutationTree } from "vuex";
import { statusFactory, Status } from "@/store/utils";
import { RootState } from "@/store/types";
import { OrganisationsApi } from "@/api/organisation-api";
import { UsersApi } from "@/api/users-api";

export interface Group {
	id: number;
	name: string;
	description: string;
}

export interface Profile {
	firstname: string;
	surname: string;
	email: string;
	nickname: string;
	cellphoneNumber: string;
	sex: string;
	image: string;
	imageThumbnail: string;
}

export interface User {
	id: string;
	profile: Profile;
	groups: Group[];
}

export interface UsersState {
	users: User[];
	status: Status;
	searchedUsers: User[];
	searching: boolean;
}

export const state: UsersState = {
	users: [],
	searchedUsers: [],
	status: statusFactory(),
	searching: false
};

const namespaced: boolean = true;

export const getters: GetterTree<UsersState, RootState> = {};

export const actions: ActionTree<UsersState, RootState> = {
	async fetchUsers({ commit, rootState }) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");
			commit("loading");
			const response = await OrganisationsApi.fetchUsers(rootState.organisations.selectedOrganisation.id);

			const users = [];
			for (let item of response.data.results) {
				users.push({ ...item.user, groups: item.groups });
			}

			commit("setUsers", users);
		} catch (error) {
			commit("error", error.message);
		}
	},

	async searchUsersByEmail({ commit }, emailQuery: string) {
		try {
			commit("searching");
			const request = await UsersApi.searchUsersByEmail(emailQuery);
			commit("searchedUsers", request.data);
		} catch (error) {
			commit("error", error);
		}
	},

	clearError({ commit }) {
		commit("clearError");
	}
};

export const mutations: MutationTree<UsersState> = {
	setUsers(state, users) {
		state.users = users;
		state.status.loading = false;
	},

	searchedUsers(state, payload) {
		state.searchedUsers = payload;
		state.searching = false;
	},

	success(state, payload) {
		state.status.success = payload || false;
		state.status.loading = false;
		state.status.error = false;
	},

	loading(state) {
		state.status.success = false;
		state.status.loading = true;
		state.status.error = false;
	},

	searching(state) {
		state.searching = true;
	},

	error(state, payload) {
		state.status.success = false;
		state.status.loading = false;
		state.status.error = payload || false;
	},

	clearError(state, payload) {
		state.status.success = false;
		state.status.loading = false;
		state.status.error = false;
	}
};

export const users: Module<UsersState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};

export default users;
