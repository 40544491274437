import { MutationTree } from "vuex";
import { ActiveOrdersState } from "@/store/organisations/activeOrders/types";

export const mutations: MutationTree<ActiveOrdersState> = {
	setOrders(state, payload) {
		state.orders = payload;
		state.loading = false;
	},

	setOrderTotal(state, payload) {
		state.totalItems = payload;
	},

	setSelectedOrder(state, payload) {
		state.selectedOrder = payload;
		state.loading = false;
	},

	setSelectedOrderAddress(state, payload) {
		state.orderAddress = payload;
	},

	setActiveOrders(state, payload) {
		state.ordersByStatus = {};
		for (let i = 0; i < payload.length; i++) {
			if (!state.ordersByStatus[payload[i].status.id]) state.ordersByStatus[payload[i].status.id] = { orders: [] };

			state.ordersByStatus[payload[i].status.id].orders.push(payload[i]);
		}

		state.loading = false;
	},

	setOrderStatusses(state, payload) {
		state.orderStatusses = payload;
	},

	setOrderStatusOverview(state, payload) {
		state.orderStatusOverview = payload;
	},

	setOrderTransactions(state, payload) {
		state.orderTransactions = payload;
	},

	setDeliverySettings(state, payload) {
		state.deliverySettings = payload;
	},

	setProviders(state, payload) {
		state.providers = payload;
	},

	setBoxSizes(state, payload) {
		state.boxSizes = payload;
	},

	setAllowedSizes(state, payload) {
		state.allowedBoxSizes = payload;
	},

	setDeliveryRestrictions(state, payload) {
		state.deliveryRestrictions = payload;
	},

	/* Grid Filters */
	setFilters(state, payload) {
		state.filters = { ...state.filters, ...payload };
	},

	/* Paging */
	paginate(state, payload) {
		state.pagination = payload;
	},

	setTotalItems(state, payload) {
		state.totalItems = payload;
	},

	loading(state, payload = true) {
		state.loading = payload;
	},

	setTransactions(state, payload) {
		state.orderTransactions = payload;
		state.loading = false;
	}
};
