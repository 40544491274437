import { Module, ActionTree, GetterTree, MutationTree } from "vuex";
import { successCommit, errorCommit } from "@/store/utils";
import { RootState } from "@/store/types";
import { SettingsApi, OrgTransactionSettings } from "@/api/settings-api";
import { AccountsAPi } from "@/api/accounts-api";

export interface SettingsObj {
	acceptCollections: boolean;
	acceptDeliveries: boolean;
	acceptOrders: boolean;
	acceptPayments: boolean;
	acceptPayOnDelivery: boolean;
	requirePayUpfront: boolean;
}

export interface AccountPermissions {
	depositIntoBankAccount: boolean;
}

export interface NotificationConfig {
	eventTypeId: string;
	partyTypeId: string;
	statusId: string;
	eventTypeName: string;
	partyTypeName: string;
	statusName: string;
}

export interface NotificationSubscription {
	email: string;
	person: string;
	config: Array<NotificationConfig>;
}

export interface PushySubscription {
	config: Array<NotificationConfig>;
}

export interface SettingsState {
	settings?: any;
	transactionSettings?: any;
	transactionPermissions?: AccountPermissions;
	loading: boolean;
	availableNotificationSubscriptions: NotificationConfig[];
	defaultNotificationSubscriptions: NotificationConfig[];
	notificationSubscription: NotificationSubscription[];
	availablePushySubscriptions: PushySubscription[];
	defaultPushySubscriptions: PushySubscription[];
}

export const state: SettingsState = {
	settings: undefined,
	transactionSettings: {} as any,
	transactionPermissions: {} as AccountPermissions,
	loading: false,
	availableNotificationSubscriptions: [],
	defaultNotificationSubscriptions: [],
	notificationSubscription: [],
	availablePushySubscriptions: [],
	defaultPushySubscriptions: []
};

const namespaced: boolean = true;

export const getters: GetterTree<SettingsState, RootState> = {
	fees(state) {
		if (!state.settings) return;

		return state.settings.fees.marketplaceTransactions;
	}
};

export const actions: ActionTree<SettingsState, RootState> = {
	async updateSettings({ commit, rootState }, settingsObj: SettingsObj) {
		try {
			commit("loading");
			if (!rootState.organisations.selectedOrganisation) throw Error("Selected organisation must exist.");
			await SettingsApi.updateSettings(rootState.organisations.selectedOrganisation.id, settingsObj);
			successCommit(commit, "Settings updated.");
		} catch (error) {
			errorCommit(commit, error);
		}
	},

	async updateFees({ commit, rootState, dispatch }, feesObj) {
		try {
			commit("loading");
			if (!rootState.organisations.selectedOrganisation) throw Error("Selected organisation must exist.");
			await SettingsApi.updateFees(rootState.organisations.selectedOrganisation.id, feesObj);
			successCommit(commit, "Settings Updated.");
			dispatch("fetchSettings");
		} catch (error) {
			errorCommit(commit, error);
		}
	},

	async updateTransactionSettings(
		{ commit, rootState, dispatch },
		settingsObj: { orgTransactionsSettings: OrgTransactionSettings; permissions: { depositIntoBankAccount: boolean } }
	) {
		try {
			commit("loading");
			if (!rootState.organisations.selectedOrganisation) throw Error("Selected organisation must exist.");
			let transactionResults = await SettingsApi.updateTransactionSettings(
				rootState.organisations.selectedOrganisation.id,
				settingsObj.orgTransactionsSettings
			);
			let permissionResult = await AccountsAPi.savePermissions(rootState.organisations.selectedOrganisation.id, settingsObj.permissions);
			commit("notifications/success", "Transaction Settings Updated", { root: true });
			if (transactionResults.status === 200 && permissionResult.status === 200) {
				dispatch("fetchTransactionSettings");
			}
		} catch (error) {
			commit("loading", false);
			dispatch("notifications/error", error, { root: true });
		}
	},

	async updateNotifications({ commit, dispatch, rootState }, notifications) {
		try {
			commit("loading");
			if (!rootState.organisations.selectedOrganisation) throw Error("Selected organisation must exist.");
			notifications = { "transactions.notification": notifications };
			await SettingsApi.updateNotifications(rootState.organisations.selectedOrganisation.id, notifications);
			commit("notifications/success", "Notifications Updated", { root: true });
			dispatch("fetchSettings");
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	async fetchSettings({ commit, rootState }) {
		try {
			commit("loading");
			if (!rootState.organisations.selectedOrganisation) throw Error("Selected organisation must exist.");
			const response = await SettingsApi.fetchSettings(rootState.organisations.selectedOrganisation.id);
			commit("setSettings", response.data);
		} catch (error) {
			errorCommit(commit, error);
		}
	},

	async fetchTransactionSettings({ commit, rootState }) {
		try {
			commit("loading");
			if (!rootState.organisations.selectedOrganisation) throw Error("Selected organisation must exist.");
			const settingsResponse = await SettingsApi.fetchTransactionSettings(rootState.organisations.selectedOrganisation.id);
			const permissionsResponse = await AccountsAPi.fetchPermissions(rootState.organisations.selectedOrganisation.id);
			commit("setTransactionSettings", settingsResponse.data);
			commit("setTransactionPermissions", permissionsResponse.data);
		} catch (error) {
			errorCommit(commit, error);
		}
	},

	async refresh({ commit }) {
		commit("setSettings", undefined);
	},

	async fetchDefaultNotificationSubscriptions({ commit }) {
		try {
			commit("loading");
			const result = await SettingsApi.fetchDefaultNotificationSubscriptions();
			commit("setDefaultNotificationSettings", result.data);
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	async fetchAvailableNotificationSubscriptions({ commit }) {
		try {
			commit("loading");
			const result = await SettingsApi.fetchAvailableNotificationSubscriptions();

			const item_order: any = {
				"Buyer/Seller": 1,
				Buyer: 2,
				Seller: 3,
				"Supplier/Customer": 1,
				Supplier: 2,
				Customer: 3,
				"Debit/Credit": 1,
				Debit: 2,
				Credit: 3
			};

			let arrayObj: any = {};
			result.data.forEach((item: any) => {
				let eventTypeId = item.eventTypeId.split(".")[0];
				let partyName = item.partyTypeName.replace(/[\s]/g, "-");

				if (!arrayObj[eventTypeId]) {
					// new
					arrayObj[eventTypeId] = {};
					if (!arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`]) {
						arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`] = [];
						arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`].push(item);
					} else {
						arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`].push(item);
					}
				} else {
					// exists
					if (!arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`]) {
						arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`] = [];
						arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`].push(item);
					} else {
						arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`].push(item);
					}
				}
			});

			// sort object
			for (const [key, value] of Object.entries(arrayObj)) {
				arrayObj[key] = Object.fromEntries(Object.entries(arrayObj[key]).sort());
			}

			commit("setAvailableNotificationSettings", arrayObj);
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	async fetchNotificationsSubscriptions({ commit }, data: { orgId: number; email: string }) {
		try {
			commit("loading");
			const result = await SettingsApi.fetchNotificationsSubscriptions(data.orgId, data.email);
			commit("setNotificationSettings", result.data);
		} catch (error) {
			commit("notifications/error", error, { root: true });
		}
	},

	async fetchAvailablePushySubscriptions({ commit, dispatch }) {
		try {
			commit("loading");
			const result = await SettingsApi.fetchAvailablePushySubscriptions();
			const item_order: any = {
				"Buyer/Seller": 1,
				Buyer: 2,
				Seller: 3,
				"Supplier/Customer": 1,
				Supplier: 2,
				Customer: 3,
				"Debit/Credit": 1,
				Debit: 2,
				Credit: 3
			};

			let arrayObj: any = {};
			result.data.forEach((item: any) => {
				let eventTypeId = item.eventTypeId.split(".")[0];
				let partyName = item.partyTypeName.replace(/[\s]/g, "-");

				if (!arrayObj[eventTypeId]) {
					// new
					arrayObj[eventTypeId] = {};
					if (!arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`]) {
						arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`] = [];
						arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`].push(item);
					} else {
						arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`].push(item);
					}
				} else {
					// exists
					if (!arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`]) {
						arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`] = [];
						arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`].push(item);
					} else {
						arrayObj[eventTypeId][`${item_order[partyName]}. ${partyName}`].push(item);
					}
				}
			});

			// sort object
			for (const [key, value] of Object.entries(arrayObj)) {
				arrayObj[key] = Object.fromEntries(Object.entries(arrayObj[key]).sort());
			}

			commit("setAvailablePushySettings", arrayObj);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async fetchDefaultPushySubscriptions({ commit, dispatch }, orgId: number) {
		try {
			commit("loading");
			const result = await SettingsApi.fetchDefaultPushySubscriptions(orgId);
			commit("setDefaultPushySettings", result.data.config);
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
		}
	},

	async updateNotifierSettings({ commit, dispatch }, data: { orgId: number; settings: NotificationSubscription }) {
		commit("loading");

		const newConfig = Object.entries(data.settings.config).map((item: any) => {
			return {
				eventTypeId: item[1].eventTypeId,
				partyTypeId: item[1].partyTypeId,
				statusId: item[1].statusId
			};
		});

		let notifications = { person: data.settings.person, config: newConfig };
		const result = await SettingsApi.updateNotifierSettings(data.orgId, data.settings.email, notifications);
		if (result.status === 200) {
			dispatch("fetchNotificationsSubscriptions", { orgId: data.orgId, email: null });
		} else {
			dispatch("notifications/error", "Update failed, please try again.", { root: true });
		}
	},

	async updatePushySettings({ commit, dispatch }, data: { orgId: number; config: Array<NotificationConfig> }) {
		commit("loading");

		const newConfig = Object.entries(data.config).map((item: any) => {
			return {
				eventTypeId: item[1].eventTypeId,
				partyTypeId: item[1].partyTypeId,
				statusId: item[1].statusId
			};
		});

		const result = await SettingsApi.updatePushySettings(data.orgId, newConfig);
		if (result.status === 200) {
			dispatch("fetchNotificationsSubscriptions", { orgId: data.orgId, email: null });
		} else {
			dispatch("notifications/error", "Update failed, please try again.", { root: true });
		}
	},

	async removeNotifier({ commit, dispatch }, data: { orgId: number; email: string }) {
		commit("loading");

		await SettingsApi.removeNotifier(data.orgId, data.email);
		commit("notifications/success", "Notifier removed", { root: true });
		dispatch("fetchNotificationsSubscriptions", { orgId: data.orgId, email: null });
	}
};

export const mutations: MutationTree<SettingsState> = {
	setSettings(state, settings) {
		state.settings = settings;
		state.loading = false;
	},

	setTransactionSettings(state, settings) {
		state.transactionSettings = settings;
		state.loading = false;
	},
	setTransactionPermissions(state, payload) {
		state.transactionPermissions = payload;
		state.loading = false;
	},

	loading(state, payload = true) {
		state.loading = payload;
	},

	setAvailableNotificationSettings(state, payload) {
		state.availableNotificationSubscriptions = payload;
		state.loading = false;
	},

	setDefaultNotificationSettings(state, payload) {
		state.defaultNotificationSubscriptions = payload;
		state.loading = false;
	},

	setAvailablePushySettings(state, payload) {
		state.availablePushySubscriptions = payload;
		state.loading = false;
	},

	setDefaultPushySettings(state, payload) {
		state.defaultPushySubscriptions = payload;
		state.loading = false;
	},

	setNotificationSettings(state, payload) {
		state.notificationSubscription = payload;
		state.loading = false;
	}
};

export const settings: Module<SettingsState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};

export default settings;
