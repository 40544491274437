import { Module, ActionTree, GetterTree, MutationTree } from "vuex";
import { RootState } from "@/store/types";
import {
	fetchCurrentConnections,
	deleteConnection,
	createConnection,
	createNewConnection,
	fetchPossibleConnections,
	acceptConnection,
	declineConnection,
	ConnectionsBody,
	fetchConnectionTypes
} from "@/api/connections-api";
import { Organisation } from ".";

export interface ConnectionType {
	id: number;
	name: string;
	code: string;
}

export interface Connection {
	id: number;
	fromOrganisation: Organisation;
	toOrganisation: Organisation;
	requestedBy: Organisation;
	type: ConnectionType;
	ended: string;
	enabled: boolean;
	status: string;
}

export interface ConnectionsState {
	connections: Connection[]; // Replace any with interface
	pendingConnections: Organisation[];
	possibleConnections: Organisation[];
	totalItems: number;
	loading: boolean;
	connectionTypes: ConnectionType[];
}

export const state: ConnectionsState = {
	totalItems: 0,
	connections: [],
	pendingConnections: [],
	possibleConnections: [],
	loading: false,
	connectionTypes: []
};

const namespaced: boolean = true;

export const getters: GetterTree<ConnectionsState, RootState> = {
	// Build the correct object for us to read for the connections view screen
	connections(state, getters, rootState) {
		if (!rootState.organisations.selectedOrganisation) return [];
		const getOrgTypes = (types: any[]) => {
			const typeName = [];
			for (let type of types) {
				typeName.push(type.name);
			}
			return typeName.join(", ");
		};

		let customersObj = [];
		for (let conn of state.connections) {
			const profile = conn.toOrganisation.profile.contactPerson ? conn.toOrganisation.profile.contactPerson.profile : null;
			const myCustObj = {
				customerCode: conn.toOrganisation.code,
				customerName: conn.toOrganisation.name,
				contactName: profile ? `${profile.firstName} ${profile.surname}` : "-",
				contactNumber: profile ? `${profile.cellphoneNumber}` : "-",
				status: conn.status,
				types: getOrgTypes(conn.toOrganisation.types),
				id: conn.id
			};

			customersObj.push(myCustObj);
		}

		return customersObj;
	},

	pendingConnections(state, getters, rootState) {
		if (!rootState.organisations.selectedOrganisation) return [];
		const getOrgTypes = (types: any[]) => {
			const typeName = [];
			for (let type of types) {
				typeName.push(type.name);
			}
			return typeName.join(", ");
		};

		let customersObj = [];
		let customerInfo = {};
		for (let conn of state.connections) {
			if (conn.requestedBy != null) {
				if (conn.requestedBy.id === rootState.organisations.selectedOrganisation.id) {
					const profile = conn.fromOrganisation.profile.contactPerson ? conn.fromOrganisation.profile.contactPerson.profile : null;
					customerInfo = {
						direction: "sell",
						customerCode: conn.fromOrganisation.code,
						customerName: conn.fromOrganisation.name,
						contactName: profile ? `${profile.firstName} ${profile.surname}` : "-",
						contactNumber: profile ? `${profile.cellphoneNumber}` : "-",
						status: conn.status,
						types: getOrgTypes(conn.fromOrganisation.types),
						id: conn.id,
						requestedBy: conn.requestedBy
					};
				} else {
					const profile = conn.toOrganisation.profile.contactPerson ? conn.toOrganisation.profile.contactPerson.profile : null;
					customerInfo = {
						direction: "buy",
						customerCode: conn.requestedBy.code,
						customerName: conn.requestedBy.name,
						contactName: profile ? `${profile.firstName} ${profile.surname}` : "-",
						contactNumber: profile ? `${profile.cellphoneNumber}` : "-",
						status: conn.status,
						types: getOrgTypes(conn.requestedBy.types),
						id: conn.id,
						requestedBy: conn.requestedBy
					};
				}
				const myCustObj = customerInfo;
				customersObj.push(myCustObj);
			}
		}

		return customersObj;
	},

	// Build the correct object for us to read for the add connection view screen
	possibleCustomers(state) {
		const possibleCustomers = state.possibleConnections;
		let customersObj = [];

		const getOrgTypes = (types: any[]) => {
			const typeName = [];
			for (let type of types) {
				typeName.push(type.name);
			}
			return typeName.join(", ");
		};

		for (let conn of possibleCustomers) {
			const profile = conn.profile && conn.profile.contactPerson ? conn.profile.contactPerson.profile : null;
			const myCustObj = {
				customerCode: conn.code,
				customerName: conn.name,
				contactName: profile ? `${profile.firstName} ${profile.surname}` : "-",
				contactNumber: profile ? `${profile.cellphoneNumber}` : "-",
				types: getOrgTypes(conn.types),
				id: conn.id
			};

			customersObj.push(myCustObj);
		}
		return customersObj;
	},

	connectionTypes(state) {
		let connectionTypes = [];
		for (let connType of state.connectionTypes) {
			const myCustObj = {
				id: connType.id,
				name: connType.name
			};

			connectionTypes.push(myCustObj);
		}

		return connectionTypes;
	},

	filterConnections(state) {
		return (type: number) => {
			const connectionTypeId = state.connectionTypes[type].id;

			const getOrgTypes = (types: any[]) => {
				const typeName = [];
				for (let type of types) {
					typeName.push(type.name);
				}
				return typeName.join(", ");
			};

			let filterConnections: any = [];
			state.connections.forEach((conn: any) => {
				conn.toOrganisation.types.forEach((type: any) => {
					if (type.id === connectionTypeId) {
						filterConnections.push(conn);
					}
				});
			});

			let customersObj = [];
			for (let conn of filterConnections) {
				const profile = conn.toOrganisation.profile.contactPerson ? conn.toOrganisation.profile.contactPerson.profile : null;
				const myCustObj = {
					customerCode: conn.toOrganisation.code,
					customerName: conn.toOrganisation.name,
					contactName: profile ? `${profile.firstName} ${profile.surname}` : "-",
					contactNumber: profile ? `${profile.cellphoneNumber}` : "-",
					status: conn.status,
					types: getOrgTypes(conn.toOrganisation.types),
					id: conn.id
				};
				customersObj.push(myCustObj);
			}

			return customersObj;
		};
	},

	filterPendingConnections(state, getter, rootState) {
		return (type: number) => {
			const connectionTypeId = state.connectionTypes[type].id;

			const activeOrgId: any = rootState.organisations.selectedOrganisation!.id;
			const pendingCustomers = state.pendingConnections;
			let customersObj: any = [];
			let customerInfo: any = {};

			const getOrgTypes = (types: any) => {
				const typeName = [];
				for (const type of types) {
					typeName.push(type.name);
				}
				return typeName.join(", ");
			};

			for (const conn of pendingCustomers) {
				if (conn.requestedBy != null) {
					let connection: any = null;
					if (conn.requestedBy.id === activeOrgId) {
						if (conn.requestedBy.id === conn.toOrganisation.id) {
							connection = conn.fromOrganisation;
						} else {
							connection = conn.toOrganisation;
						}
					} else if (conn.requestedBy.id === conn.toOrganisation.id) {
						connection = conn.toOrganisation;
					} else {
						connection = conn.fromOrganisation;
					}

					connection.types.forEach((type: any) => {
						if (type.id === connectionTypeId) {
							const profile = connection.profile && connection.profile.contactPerson ? connection.profile.contactPerson.profile : null;
							customerInfo = {
								direction: conn.requestedBy.id === activeOrgId ? "sell" : "buy",
								customerCode: connection.code,
								customerName: connection.name,
								contactName: profile ? `${profile.firstName} ${profile.surname}` : "-",
								contactNumber: profile && profile.contactNumber ? `${profile.contactNumber}` : "-",
								contactAddress:
									connection.address && connection.address.city != null && connection.address.province != null
										? connection.address.city + ", " + connection.address.province
										: "-",
								status: conn.status,
								types: getOrgTypes(connection.types),
								id: conn.id,
								requestedBy: conn.requestedBy,
								dateCreated: conn.dateCreated
							};

							const myCustObj = customerInfo;
							customersObj.push(myCustObj);
						}
					});
				}
			}
			return customersObj;
		};
	}
};

export const actions: ActionTree<ConnectionsState, RootState> = {
	async fetchPossibleConnections({ commit, rootState }, payload: ConnectionsBody) {
		try {
			if (!rootState.organisations.selectedOrganisation) return;
			commit("loading");
			const result = await fetchPossibleConnections(rootState.organisations.selectedOrganisation.id, payload);
			commit("setPossibleConnections", result.data.results);
			commit("setTotalItems", result.data.count);
		} catch (error) {
			commit("notifications/error", error, { root: true });
			commit("loading", false);
		}
	},

	async fetchConnections({ commit, rootState }, payload) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");
			commit("setConnections", []);
			commit("loading");
			const result = await fetchCurrentConnections(rootState.organisations.selectedOrganisation.id, payload);
			commit("setConnections", result.data.results);
			commit("setTotalItems", result.data.count);
		} catch (error) {
			commit("notifications/error", error, { root: true });
			commit("loading", false);
		}
	},

	async fetchConnectionsPending({ commit, rootState }, payload) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");
			commit("loading");
			const result = await fetchCurrentConnections(rootState.organisations.selectedOrganisation.id, payload);
			commit("setPendingConnections", result.data.results);
			commit("setTotalItems", result.data.count);
		} catch (error) {
			commit("notifications/error", error, { root: true });
			commit("loading", false);
		}
	},

	async deleteConnections({ commit, dispatch }, connectionId) {
		try {
			commit("loading");
			await deleteConnection(connectionId);
			commit("notifications/success", "Connection has been deleted!", { root: true });
			dispatch("fetchConnections");
		} catch (error) {
			commit("notifications/error", error, { root: true });
			commit("loading", false);
		}
	},

	async createConnection({ commit, rootState }, connectionObj) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");

			commit("loading");
			await createConnection(connectionObj.toOrganisationId, rootState.organisations.selectedOrganisation.id, connectionObj.typeCode);
			commit("notifications/success", "Connection has been added!", { root: true });
			return true;
		} catch (error) {
			commit("notifications/error", error.response.data.message, { root: true });
			commit("loading", false);
		}
	},

	async createNewConnection({ commit, rootState }, toOrganisationId: number) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");
			commit("loading");
			await createNewConnection(rootState.organisations.selectedOrganisation.id, toOrganisationId);
			commit("notifications/success", "Connection has been added!", { root: true });
			return true;
		} catch (error) {
			commit("notifications/error", error.response.data.message, { root: true });
			commit("loading", false);
		}
	},

	async acceptConnection({ commit, dispatch, rootState }, connectionId) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");

			commit("loading");
			await acceptConnection(rootState.organisations.selectedOrganisation.id, connectionId);
			commit("notifications/success", "Customer Approved!", { root: true });
			return true;
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
			commit("loading", false);
		}
	},

	async declineConnection({ commit, dispatch, rootState }, connectionId) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");

			commit("loading");
			await declineConnection(rootState.organisations.selectedOrganisation.id, connectionId);
			commit("notifications/success", "Customer Declined!", { root: true });
			return true;
		} catch (error) {
			dispatch("notifications/error", error, { root: true });
			commit("loading", false);
		}
	},

	async fetchConnectionTypes({ commit, rootState }, payload) {
		try {
			if (!rootState.organisations.selectedOrganisation) throw new Error("No selected organisation found!");

			if (state.connectionTypes.length > 0) return; // No need to get connection types again
			commit("setConnections", []);
			const result = await fetchConnectionTypes();
			// remove `Marketplace`
			let types = result.data.results.filter((el: any) => el.id !== 1);
			commit("setConnectionTypes", types);
		} catch (error) {
			commit("notifications/error", error, { root: true });
			commit("loading", false);
		}
	}
};

export const mutations: MutationTree<ConnectionsState> = {
	setConnections(state, connections) {
		state.connections = connections;
		state.loading = false;
	},

	setPendingConnections(state, payload = []) {
		state.pendingConnections = payload;
		state.loading = false;
	},

	setPossibleConnections(state, payload = []) {
		state.possibleConnections = payload;
		state.loading = false;
	},

	setTotalItems(state, payload) {
		state.totalItems = payload;
	},

	loading(state, payload = true) {
		state.loading = payload;
	},

	setConnectionTypes(state, connectionTypes) {
		state.connectionTypes = connectionTypes;
	}
};

export const connections: Module<ConnectionsState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};

export default connections;
