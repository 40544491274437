import { Module } from "vuex";
import { getters } from "@/store/organisations/activeOrders/getters";
import { actions } from "@/store/organisations/activeOrders/actions";
import { mutations } from "@/store/organisations/activeOrders/mutations";
import { RootState } from "@/store/types";
import { ActiveOrdersState, DeliveryRestrictions, Order, Providers } from "@/store/organisations/activeOrders/types";
import { pagingDefault } from "@/store/utils";
import { Address } from "@/store/organisations/addresses";

export const state: ActiveOrdersState = {
	orders: [],
	selectedOrder: {} as Order,
	orderAddress: {} as Address,
	activeOrderIds: [2, 3, 5, 6],
	ordersByStatus: {},
	orderStatusses: [],
	orderStatusOverview: [],
	providers: [],
	deliverySettings: {} as Providers,
	deliveryRestrictions: {} as DeliveryRestrictions,
	boxSizes: [],
	allowedBoxSizes: [],
	orderTransactions: [],
	loading: false,
	pagination: pagingDefault,
	totalItems: 0,
	filters: {
		fromDate: "",
		toDate: "",
		orderstatus: [],
		paymentstatus: [],
		orgsearch: "",
		sort: "date-"
	}
};

const namespaced: boolean = true;

export const activeOrders: Module<ActiveOrdersState, RootState> = {
	namespaced,
	state,
	getters,
	actions,
	mutations
};

export default activeOrders;
